<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-4 px-4 sm:py-4 sm:px-4 lg:px-8">
      <div class="text-center">
        <p class="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">404 - PAGE INTROUVABLE</p>
        <br/>
        <button @click="back" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          retour à l'accueil
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    back () {
      this.$router.push('/information')
    }
  }
}
</script>
<style scoped>
  #gif {
    margin:auto;
    height: 400px
  }
</style>
