<template>
  <section class="lp flex flex-col items-center">
    <h3 class="c-title">Mot de passe</h3>

    <p class="subtitle">
      « Indique ton adresse mail pour réinitialiser ton mot de passe ».
    </p>

    <p v-if="errorMessage" class="mt-4" style="color: red">{{errorMessage}}</p>

    <div class="w-full max-w-xs ">
      <form class="bg-white rounded px-8 pt-6 pb-8 mb-4" ref="form" v-on:submit="submit">
        <div class="up__input">

          <div class="flex items-center border-b border-teal-500 py-2 mb-8">
            <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                   type="email"
                   placeholder="Email"
                   aria-label="Email"
                   v-model="email"
                   required>
          </div>
        </div>

        <div class="flex justify-center">
          <button class="bg-gray-700 hover:bg-gray-900  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
            Envoyer
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ForgotPassword',
  data () {
    return {
      email: '',
      errorMessage: ''
    }
  },
  methods: {
    submit: async function () {
      event.preventDefault()
      await axios.post('/api/auth/password', { email: this.email })
        .then(r => {
          if (r.status === 200) this.errorMessage = "Un message a été envoyé sur ton adresse email."
        })
        .catch(e => {
          this.errorMessage = e.response.data
        })
    }
  }
}
</script>

<style scoped>
.subtitle{
  max-width: 240px;
}

.lp {
  flex: 1 1 auto;
  justify-content: center;
}
</style>
