<template>
  <section class="lp flex flex-col items-center">
    <h3 class="c-title">Change ton mot de passe</h3>

    <p class="subtitle">
      « Indique ton nouveau mot de passe ».
    </p>

    <p v-if="errorMessage && !successMessage" style="color: red" class="mt-3">{{errorMessage}}</p>
    <p v-if="!errorMessage && successMessage" style="color: green" class="mt-3">{{successMessage}}</p>

    <div class="w-full max-w-xs ">
      <form class="bg-white rounded px-8 pt-6 pb-8 mb-4 " v-on:submit="submit">
        <div class="up__input">

          <div class="flex items-center border-b border-teal-500 py-2 mb-8">
            <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                   type="text"
                   placeholder="mot de passe"
                   aria-label="password"
                   v-model="password"
                   required>
          </div>

                    <div class="flex items-center border-b border-teal-500 py-2 mb-8">
            <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                   type="text"
                   placeholder="confirme ton mot de passe"
                   aria-label="passwordConfirm"
                   v-model="passwordConfirm"
                   required>
          </div>

            <input type="text"
                   aria-label="user_id"
                   v-model="user_id"
                   hidden>

        </div>

        <div class="flex justify-center">
          <button type="submit" class="bg-gray-700 hover:bg-gray-900  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Envoyer
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

const url = new URL(window.location.href)
const id = url.searchParams.get('user_id')

export default {
  name: 'ResetPassword',
  data () {
    return {
      errorMessage: '',
      successMessage: '',
      password: '',
      passwordConfirm: '',
      user_id: ''
    }
  },
  methods: {
    submit: async function () {
      if (this.password && this.passwordConfirm) {
        event.preventDefault()

        if (this.password !== this.passwordConfirm) {
          this.errorMessage = 'Les mots de passe ne correspondent pas.'
        } else {
          await axios.post('/api/auth/resetPassword', { password: this.password, passwordConfirm: this.passwordConfirm, user_id: id })
            .then(r => {
              this.errorMessage = ''
              if (r.status === 200) this.$router.push('/login')
            })
            .catch(e => {
              this.successMessage = ''
              this.errorMessage = e.response.data
            })
        }
      }
    }
  }
}
</script>

<style scoped>
.subtitle{
  max-width: 240px;
}

.lp {
  flex: 1 1 auto;
  justify-content: center;
}
</style>
