<template>
  <section class="lp">
    <form ref="form" v-on:submit="submit">
      <div>
        <h3 class="rp__title-first mb-4">Créer un compte</h3>
        <h5 id="errorMessage" class="error"></h5>

        <div class="rp__inner flex justify-content-around">

          <div class="flex flex-wrap">

            <div class="rp__input">

              <div class="flex items-center border-b border-teal-500 py-2">
                <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      placeholder="Pseudo"
                      aria-label="Pseudo"
                      v-model="userProfile.pseudo"
                      required>
              </div>

            </div>

            <div class="rp__input">

              <div class="flex items-center border-b border-teal-500 py-2">
                <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      v-model="userProfile.email"
                      placeholder="Email"
                      aria-label="Email"
                      type="email"
                      aria-describedby="basic-addon2"
                      required>
              </div>

            </div>

            <div class="rp__input">

              <div class="flex items-center border-b border-teal-500 py-2">
                <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="password"
                      minlength="6"
                      v-model="userProfile.password"
                      placeholder="Mot de passe"
                      aria-label="Mot de passe"
                      aria-describedby="basic-addon2"
                      required>
              </div>

            </div>

            <div class="rp__input">

              <div class="flex items-center border-b border-teal-500 py-2">
                <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="password"
                      minlength="6"
                      placeholder="Confirme ton mot de passe"
                      aria-label="Confirmation"
                      aria-describedby="basic-addon2"
                      v-model="userProfile.confirmation"
                      required>
              </div>

            </div>
            <div class="rp__input">
              <label for="birthday" class="block text-sm mt-2 font-medium text-gray-700">Date de naissance: </label>
              <div class="flex items-center border-b border-teal-500 py-2">
                <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="date"
                      v-model="userProfile.birthday"
                      placeholder="date de naissance"
                      aria-label="date de naissance"
                      aria-describedby="basic-addon2"
                      required>
              </div>

            </div>

            <div class="rp__input">

              <div class="mt-4 flex items-center border-b border-teal-500 py-2">
                <label for="sexe">Sexe:</label>
                <select class="custom-select"
                        v-model="userProfile.sexe"
                        id="inputGroupSelect04"
                        aria-label="Example select with button addon"
                        required>
                  <option value="F">Féminin</option>
                  <option value="M">Masculin</option>
                  <option value="autre">Autre</option>
                </select>
              </div>

            </div>

            <div class="rp__input postal_code">

              <div class="flex items-center border-b border-teal-500 py-2">
                <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      v-model="userProfile.postalCode"
                      placeholder="Code postal"
                      aria-label="Code postal"
                      aria-describedby="basic-addon2"
                      required>
              </div>

              <div class="input-group flex mt-3">
                <label for="village">Village:</label>
                <select class="custom-select"
                        v-model="userProfile.village"
                        id="inputGroupSelect04"
                        aria-label="Example select with button addon"
                        required>
                  <option value="Bierwart">Bierwart</option>
                  <option value="Cortil-Wodon">Cortil-Wodon</option>
                  <option value="Forville">Forville</option>
                  <option value="Franc-Waret">Franc-Waret</option>
                  <option value="Hemptinne">Hemptinne</option>
                  <option value="Hingeon">Hingeon</option>
                  <option value="Marchovelette">Marchovelette</option>
                  <option value="Noville-les-Bois">Noville-les-Bois</option>
                  <option value="Pontillas">Pontillas</option>
                  <option value="Tillier">Tillier</option>

                </select>
              </div>

            </div>

          </div>

        </div>

        <h3 class="rp__title-second pt-5">Centre d’intérêt</h3>

        <div class="rp__checkbox-container flex  flex-wrap pb-3">
            <div v-for="(checkbox, i) in checkboxArray"
                :key="i"
                class="rp__checkbox-item">
              <div class="rp__checkbox-item_inner">
                <label class="rp__checkbox custom-control custom-checkbox flex">
                  <input type="checkbox"
                        v-model="userProfile.interests"
                        :value="checkbox"
                        class="form-checkbox h-4 w-4 text-gray-600 cursor-pointer self-center"
                        checked>
                  <span class="ml-2 text-gray-700 cursor-pointer d-block">{{ checkbox }}</span>
                </label>
              </div>
          </div>

        </div>

      </div>

      <button class="bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3"
              type="submit">
        Créer
      </button>
    </form>
  </section>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'RegistrationPage',
    data () {
      return {
        userProfile: {
          pseudo: '',
          email: '',
          sexe: '',
          birthday: '',
          postalCode: '',
          village: '',
          password: '',
          confirmation: '',
          interests: []
        },
        checkboxArray: ['Musique', 'Jeux', 'Сinéma', 'Lecture', 'Art', 'Sport', 'Technologie', 'Mouvement de jeunesse', 'Autre']
      }
    },
    methods: {
      submit: function () {
        if (this.userProfile.pseudo && this.userProfile.email && this.userProfile.sexe && this.userProfile.birthday && this.userProfile.postalCode && this.userProfile.village && this.userProfile.password && this.userProfile.confirmation) {
          event.preventDefault()
          let errorMessage = document.getElementById("errorMessage")

          if (this.userProfile.password !== this.userProfile.confirmation) {
            errorMessage.innerText = 'Les mots de passe ne correspondent pas.'
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          } else if (this.userProfile.postalCode != 5380) {
            errorMessage.innerText = 'Cette application est destinée uniquement aux jeunes de la commune de Fernelmont. \nMerci de votre compréhension.'
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          } else {
            this.userProfile.email = this.userProfile.email.toLowerCase()
            axios.post('/api/auth/add_user', this.userProfile)
            .then(r => {
              if (r.data && r.data != "OK") {
                errorMessage.innerText = r.data
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
              } else {
                if (r.status === 200){
                  axios.post(`/api/auth/login`, this.userProfile)
                    .then(r => {
                      this.errorMessage = ''
                      localStorage.setItem('token', JSON.stringify(r.data.token))
                      if (r.status === 200) {
                        this.$emit('login', true)
                        this.$router.push('/information')
                      }
                    })
                    .catch(e => {
                      this.errorMessage = e.response.data
                    })
                }
              }
            })
            .catch(e => console.log('error: ', e))
          }
        }
      }
    }
  }
</script>

<style scoped>
  .rp__wrapper {
    margin: 0 auto;
  }

  label {
    padding-right: 10px
  }

  .error {
    color: red
  }

  .lp {
    padding: 3rem 0;
  }

  .rp__input {
    max-width: 45%;
    width: 100%;
    margin: 0 1rem;
  }

  .rp__checkbox-item_inner {
    display: flex;
    max-width: 200px;
    margin: 0 auto;
    width: 100%;
  }

  .rp__inner,
  .rp__checkbox-container {
    max-width: 725px;
    margin: 0 auto;
    width: 100%;
    padding: 0 1rem;
  }

  /*.rp__checkbox-container {*/
  /*  max-width: 635px;*/
  /*}*/

  .rp__checkbox {
    width: 100%;
    text-align: left;
    padding-right: 0;
  }

  .rp__checkbox-item {
    max-width: 230px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
  }

  .rp__title-first,
  .rp__title-second {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 1rem;
  }

  .input-group > .custom-select {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    .lp {
      padding: 1rem 0;
    }

    .rp__checkbox-item {
      margin: 0.5rem 0;
      max-width: initial;
    }

    .rp__checkbox {
      max-width: 200px;
    }

    .rp__checkbox-container {
      justify-content: center;
    }

    .rp__inner > .flex.flex-wrap {
      flex-direction: column;
      align-content: center;
    }

    .rp__inner {
      justify-content: center;
    }

    .rp__input {
      max-width: initial;
    }
  }

  @media screen and (max-width: 560px) {
    .rp__inner > .flex.flex-wrap {
      flex-direction: column;
    }
  }
</style>
