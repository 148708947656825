<template>
  <section class="lp flex flex-col items-center">
    <h3 class="c-title">Se connecter</h3>

    <p v-if="errorMessage" style="color: red">{{errorMessage}}</p>

    <div class="w-full max-w-xs ">
      <form ref="form" class="bg-white rounded px-4 pt-2 pb-2 mb-4" v-on:submit="submit">

        <div class="up__input">

          <div class="flex items-center border-b border-teal-500 py-2">
            <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                   type="text"
                   placeholder="Pseudo / Email"
                   aria-label="Email"
                   v-model="user.email"
                   required>
          </div>

        </div>

        <div class="up__input">

          <div class="flex items-center border-b border-teal-500 py-2 mb-5">
            <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                   type="password"
                   placeholder="Mot de passe"
                   aria-label="Mot de passe"
                   v-model="user.password"
                   required>
          </div>

        </div>

        <div class="flex flex-col items-left text-left">
          <label class="inline-flex items-center mb-3">
            <input checked type="checkbox" class="form-checkbox h-4 w-4 text-gray-600" v-model="user.rememberUser">
            <span class="ml-2">Se souvenir de moi</span>
          </label>

          <div class="flex items-center justify-center mt-3">
            <button class="bg-gray-700 hover:bg-gray-900  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
              Connexion
            </button>
          </div>

          <a  class="text-center inline-block align-baseline font-bold text-sm text-gray-700 hover:text-gray-900 mb-2" href="/forgot-password">
            Mot de passe oublié ?
          </a>

          <a v-if="isInstall" @click="installMe" id="installButton" class="text-center inline-block align-baseline font-bold text-sm text-gray-700 hover:text-gray-900 mb-2">
            Installe moi !
          </a>


          <router-link to="/registration" class="text-center inline-block align-baseline font-bold text-sm text-gray-700 hover:text-gray-900 mb-2">
            Créer un compte
          </router-link>

        </div>

      </form>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LoginPage',
  emits: ["login"],
  data () {
    return {
      user: {
        email: '',
        password: '',
        rememberUser: false
      },
      errorMessage: '',
      isInstall: false,
      deferredPrompt: ''
    }
  },
  async created () {
    // Initialize deferredPrompt for use later to show browser install prompt.
    window.addEventListener('beforeinstallprompt', (evt) => {
        // Stash the event so it can be triggered later.
        this.deferredPrompt = evt;

        // creating install button
        console.log("beforeinstallprompt detected");
        this.isInstall= true;
    });
  },
  methods: {
    submit: function () {
      if (this.user.email && this.user.password) {
        event.preventDefault()

        axios.post('/api/auth/login', this.user)
          .then(r => {
            this.errorMessage = ''
            localStorage.setItem('token', JSON.stringify(r.data.token))
            if (r.status === 200) {
              this.$emit('login', true)
              this.$router.push('/information')
            }
          })
          .catch(e => {
            this.errorMessage = e.response.data
          })
      }
    },
    async installMe () {
      // Show the install prompt
      this.deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.userChoice;

      // Optionally, send analytics event with outcome of user choice
      console.log(`User response to the install prompt: ${outcome}`);

      // We've used the prompt, and can't use it again, throw it away
      deferredPrompt = null;
      this.isInstall= false;
    }
  }
}
</script>

<style scoped>
.lp {
  flex: 1 1 auto;
  justify-content: center;
  max-width: 300px;
  margin: 0 auto;
}
</style>
